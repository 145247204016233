import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --color-black: #373737;
    --color-white: #fff;
    --color-grey: #9C9C9C;
    --color-text: var(--color-black);
    --color-background: var(--color-white);
    --color-primary: #42B293;
    --color-green: #42A62A;
    --color-slideshow-inactive: #e5e5e5;
    --color-grey-light: #e5e5e5;

    --gutter: 1.25rem; //20

    ${mq.small} {
      --gutter: 10rem; //160
    }

    --navigation-height: 4.375rem; //70
    
    ${mq.small} {
      --navigation-height: 13rem; //207
    }

    --font-family: 'Noto Sans', sans-serif;
    --font-family-serif: 'Zilla Slab', serif;
    
    --motion: 300ms;
    --motion-double: 600ms;
    --motion-triple: 900ms;
  }
`;

export default Vars;
