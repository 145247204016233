import mq, { hover, mqdown } from '@mq';
import { css, keyframes } from 'styled-components';

const layoutWrapper = css`
  padding-left: var(--gutter);
  padding-right: var(--gutter);
`;

const grid = css`
  ${layoutWrapper};
  display: grid;
  column-gap: var(--grid-gap);
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
`;

const hyphenate = css`
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`;

const linkHover = css`
  ${hover} {
    transition: color var(--motion-half) ease-out;

    svg {
      path {
        transition: fill var(--motion-half) ease-out;
      }
    }

    &:hover {
      color: var(--color-grey);

      svg {
        path {
          fill: var(--color-grey);
        }
      }
    }
  }
`;

const linkHoverLight = css`
  ${hover} {
    transition: color var(--motion-half) ease-out;

    svg {
      path {
        transition: fill var(--motion-half) ease-out;
      }
    }

    &:hover {
      color: var(--color-grey-light);

      svg {
        path {
          fill: var(--color-grey-light);
        }
      }
    }
  }
`;

const truncate = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

const richText = css`
  ${mqdown.small} {
    ${hyphenate};
  }

  p {
    white-space: pre-line;
    z-index: 1;
    position: relative;
  }

  p + p {
    margin-top: 1em;
  }

  //first paragraph after an h2 or h3
  h2 + p,
  h3 + p {
    margin-top: 0.75rem;
  }

  h1 {
    font-family: var(--font-family-serif);
    font-size: 1.625rem;
    font-weight: 700;

    ${mq.small} {
      font-size: 4.375rem;
    }
  }

  h2 {
    margin-top: 2rem;
    font-size: 1.6875rem;
    font-family: var(--font-family-serif);
    font-weight: 700;

    ${mq.small} {
      font-size: 3rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-family: var(--font-family-serif);
    font-weight: 700;

    ${mq.small} {
      font-size: 1.875rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h3 + h3 {
    margin-top: 0;
  }

  h2 + h3 {
    margin-top: 1.5rem;
  }

  ul {
    margin-top: 1em;
    margin-bottom: 1em;

    li {
      display: flex;

      &:before {
        content: '•';
        display: block;
        margin: 0 1rem;
        height: 100%;
      }
    }
  }

  a {
    ${linkHover};
  }
`;

const appearKeyframes = keyframes`
  from {
    opacity:  0;
  }

  to {
    opacity:  1;
  }
`;

const appear = css`
  animation: ${appearKeyframes} 1s var(--motion-delay, 0s) backwards;
`;

const transition = {
  transform: css`
    transform: translate3d(0, 0, 0);
    transition: transform var(--motion) ease-out;
  `,
};

const flexGrid = ({ columnGap = '2rem', rowGap = columnGap }) => css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * ${rowGap} / 2) calc(-1 * ${columnGap} / 2);

  > * {
    margin: calc(${rowGap} / 2) calc(${columnGap} / 2);
  }
`;

export {
  richText,
  grid,
  truncate,
  layoutWrapper,
  center,
  hyphenate,
  // hover,
  cover,
  appear,
  transition,
  flexGrid,
  linkHover,
  linkHoverLight,
};
