import React, { useEffect, useState } from 'react';
import GlobalStyles from '@styles/GlobalStyles';

import Navigation from '@core/Navigation';
import Seo from '@core/Seo';

const Layout = ({ children, pageContext: { pageName } }) => {
  const isPracticePage = pageName === 'Maximilianstraße' || pageName === 'Sternbergstraße';

  return (
    <>
      <Seo />
      <GlobalStyles />
      <Navigation isPracticePage={isPracticePage} />
      {children}
    </>
  );
};

export default Layout;
