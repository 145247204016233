import React from 'react';
import styled, { css } from 'styled-components';
import withLink from '@common/withLink';
import { hover } from '@mq';
import { linkHover } from '@mixins';

const Link = ({ title, children, ...rest }) => (
  <Container title={title} {...rest}>
    {children || title}
  </Container>
);

const Container = styled.a`
  ${props => props.to && linkHover}
`;

export default withLink(Link);
